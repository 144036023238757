import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';

import escapeXMLChars from './helpers/escapeXMLChars'
import templateXML from '../templates/templateImportInvoice'
import hostDocument from '../templates/templateImportInvoice.xlsx'


const generateImportInvoice = importFile => {
  fetch(templateXML)
    .then((r) => r.text())
    .then(xmlText => {
      if (importFile.billTo === "consignee_invoice"){
        xmlText = xmlText.replace("_billToOtherReplace", 
          `${importFile.consigneeName}
${importFile.consigneeAddress}
TEL: ${importFile.consigneePhoneNo} FAX: ${importFile.consigneeFaxNo}
${importFile.consigneeContact}`)
      }
      for (let key in importFile) {
        if (importFile.hasOwnProperty(key)) {
          if (key === 'descriptionOfGoods')
            importFile[key] = importFile[key].split('\n')[0]
          xmlText = xmlText.replace("_" + key + "Replace", escapeXMLChars(importFile[key]))
        }
      }
      xmlText = xmlText.replace("_currentDateReplace", new Date().toLocaleDateString())
      xmlText = xmlText.replace("_fileNoReplace", importFile.fileNo)

      let invoiceDesc = ""
      let invoicePrepaid = ""
      let invoiceCollect = ""
      let invoicePrepaidTotal = 0
      let invoiceCollectTotal = 0
      if (importFile.billTo === "other" || importFile.billTo === "consignee_invoice") {
        let invoice = JSON.parse(importFile.invoice)
        console.log(invoice)
        for (let billingItem in invoice) {
          invoiceDesc = invoiceDesc + invoice[billingItem].desc + "\n"
          invoicePrepaid = invoicePrepaid + ((invoice[billingItem].prepaid != 0) ? "$ " + parseFloat(invoice[billingItem].prepaid).toFixed(2) : "") + "\n"
          invoiceCollect = invoiceCollect + ((invoice[billingItem].collect != 0) ? "$ " + parseFloat(invoice[billingItem].collect).toFixed(2) : "") + "\n"
          invoicePrepaidTotal += parseFloat(invoice[billingItem].prepaid)
          invoiceCollectTotal += parseFloat(invoice[billingItem].collect)
        }
      }

      if (importFile.cargo) {
        invoiceDesc = invoiceDesc + "\n\n\n\n\n"
        let containerPackages = 0
        let cargoGrossWeight = 0
        let cargo = JSON.parse(importFile.cargo)
        for (let cargoItem in cargo) {
          invoiceDesc = invoiceDesc + `** CNTR# ${cargo[cargoItem].containerNo}`
          containerPackages += parseFloat(cargo[cargoItem].quantity)
          cargoGrossWeight += parseFloat(cargo[cargoItem].weight)
        }

        xmlText = xmlText.replace("_containerPackagesTotalReplace", `${containerPackages.toFixed(0)}`)
        xmlText = xmlText.replace("_cargoWeightsMeasurementsReplace", `${cargoGrossWeight.toFixed(0)}`)
      }

      xmlText = xmlText.replace("_invoiceDescReplace", invoiceDesc)
      xmlText = xmlText.replace("_invoicePrepaidReplace", invoicePrepaid)
      xmlText = xmlText.replace("_invoiceCollectReplace", invoiceCollect)
      xmlText = xmlText.replace("_invoicePrepaidTotalReplace", (invoicePrepaidTotal > 0) ? "$ " + invoicePrepaidTotal.toFixed(2) : "-")
      xmlText = xmlText.replace("_invoiceCollectTotalReplace", (invoiceCollectTotal > 0) ? "$ " + invoiceCollectTotal.toFixed(2) : "-")

      JSZipUtils.getBinaryContent(hostDocument, function (err, data) {
        if (err) {
          throw err
        }

        JSZip.loadAsync(data).then((loadedDocument) => {
          loadedDocument.file("xl/sharedStrings.xml", xmlText)
          loadedDocument.generateAsync({ type: "blob" })
            .then(function (blob) {
              saveAs(blob, importFile.fileNo + " - Invoice.xlsx")
            })
        })
      })
    })
}

export default generateImportInvoice
