// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'
import { Form, Divider, Header, Checkbox } from 'semantic-ui-react'

class ImportForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: [{ desc: "", prepaid: "0", collect: "0" }],
      cargo: [{ containerNo: "", sealNo: "", containerType: "", weight: "", quantity: "", units: "", measurement: "" }],
      billToOther: false,
    }
    if (this.props.import) {
      //Legacy Import System
      if (this.props.import.containerNo)
        this.state.cargo = [{
          containerNo: this.props.import.containerNo,
          sealNo: this.props.import.containerNo,
          containerType: "1x" + this.props.import.containerSize + "'" + this.props.import.containerType,
          weight: this.props.import.cargoGrossWeight,
          quantity: this.props.import.cargoQuantity,
          units: this.props.import.cargoUnit,
          measurement: this.props.import.cargoMeasurement
        }]


      if (this.props.import.billTo)
        this.state.billToOther = (this.props.import.billTo === "other") ? true : false
      if (this.props.import.cargo)
        this.state.cargo = JSON.parse(this.props.import.cargo)
      if (this.props.import.invoice)
        this.state.invoice = JSON.parse(this.props.import.invoice)
    }
  }

  addCargoItem = () => {
    let cargo = this.state.cargo
    cargo.push({ containerNo: "", sealNo: "", containerType: "", weight: "", quantity: "", units: "", measurement: "" })
    this.setState({ cargo: cargo })
  }

  onChangeCargoItem = (e) => {
    let cargo = this.state.cargo
    let info = e.target.name.split('_')
    let index = parseInt(info[0])
    let field = info[1]
    cargo[index][field] = e.target.value
    this.setState({ cargo: cargo })
  }

  removeCargoItem = (index) => {
    let cargo = this.state.cargo
    cargo.splice(index, 1)
    this.setState({ cargo: cargo })
  }

  onChangeBillTo = (e) => {
    this.setState({ billToOther: (e.target.value === "other") ? true : false })
  }

  addBillingItem = () => {
    let invoice = this.state.invoice
    invoice.push({ desc: "", prepaid: "0", collect: "0" })
    this.setState({ invoice: invoice })
  }

  onChangeBillingItem = (e) => {
    let invoice = this.state.invoice
    let info = e.target.name.split('_')
    let index = parseInt(info[0])
    let field = info[1]
    invoice[index][field] = e.target.value
    this.setState({ invoice: invoice })
  }

  removeBillingItem = (index) => {
    let invoice = this.state.invoice
    invoice.splice(index, 1)
    this.setState({ invoice: invoice })
  }

  onSubmit = event => {
    event.preventDefault()
    const { fileNo, mblNo, hblNo, importer, notifyParty, etd, eta, fileStatus,
      vesselName, voyageNo, portOfLoading, portOfDischarge, finalDestination,
      entryDate, itNo, itDate, itPlace, freightLocation, firmCode,
      lastFreeDate, placeOfDelivery, placeOfDeliveryEta, finalDestinationEta,
      amshblNo, descriptionOfGoods, originalBLRequired, shipperOwnedContainer, freightCharge, containerReturnLocation,
      shipperName, shipperAddress, shipperPhoneNo, shipperFaxNo, shipperContact,
      consigneeName, consigneeAddress, consigneePhoneNo, consigneeFaxNo, consigneeContact,
      notifyAddress, notifyPhoneNo, notifyFaxNo, notifyContact, billTo, billToOther, submitButton } = event.target.elements

    const values = {
      fileNo: fileNo.value.toUpperCase(),
      mblNo: mblNo.value,
      hblNo: hblNo.value,
      importer: importer.value,
      notifyParty: notifyParty.value,
      etd: etd.value,
      eta: eta.value,
      fileStatus: fileStatus.checked,
      vesselName: vesselName.value,
      voyageNo: voyageNo.value,
      portOfLoading: portOfLoading.value,
      portOfDischarge: portOfDischarge.value,
      finalDestination: finalDestination.value,
      entryDate: entryDate.value,
      itNo: itNo.value,
      itDate: itDate.value,
      itPlace: itPlace.value,
      freightLocation: freightLocation.value,
      firmCode: firmCode.value,
      lastFreeDate: lastFreeDate.value,
      placeOfDelivery: placeOfDelivery.value,
      placeOfDeliveryEta: placeOfDeliveryEta.value,
      finalDestinationEta: finalDestinationEta.value,
      amshblNo: amshblNo.value,
      cargo: JSON.stringify(this.state.cargo),
      descriptionOfGoods: descriptionOfGoods.value,
      originalBLRequired: originalBLRequired.value,
      shipperOwnedContainer: shipperOwnedContainer.value,
      containerReturnLocation: containerReturnLocation.value,
      freightCharge: freightCharge.value,
      shipperName: shipperName.value,
      shipperAddress: shipperAddress.value,
      shipperPhoneNo: shipperPhoneNo.value,
      shipperFaxNo: shipperFaxNo.value,
      shipperContact: shipperContact.value,
      consigneeName: consigneeName.value,
      consigneeAddress: consigneeAddress.value,
      consigneePhoneNo: consigneePhoneNo.value,
      consigneeFaxNo: consigneeFaxNo.value,
      consigneeContact: consigneeContact.value,
      notifyAddress: notifyAddress.value,
      notifyPhoneNo: notifyPhoneNo.value,
      notifyFaxNo: notifyFaxNo.value,
      notifyContact: notifyContact.value,
      billTo: billTo.value,
      billToOther: (this.state.billToOther) ? billToOther.value : "",
      invoice: JSON.stringify(this.state.invoice),
    }

    if (!submitButton.checked)
      this.props.onSubmit(values)
    else {
      this.props.onAltSubmit(values)
    }
  }

  render() {
    return <Form onSubmit={this.onSubmit}>
      <Form.Input type="text" size="big" label="File No." name="fileNo" defaultValue={this.props.import ? this.props.import.fileNo : ''} required />
      <Form.Input type="text" size="big" label="Importer" name="importer" defaultValue={this.props.import ? this.props.import.importer : ''} required />
      <Divider hidden />
      <Checkbox toggle label="Mark as Complete" name="fileStatus" defaultChecked={this.props.import ? this.props.import.fileStatus : ''} />
      <Divider hidden />
      <Divider />
      <Header as="h4">Vessel and Destination Information</Header>
      <Form.Group widths="equal">
        <Form.Input type="text" placeholder="Vessel Name" name="vesselName" defaultValue={this.props.import ? this.props.import.vesselName : ''} />
        <Form.Input type="text" placeholder="Voyage/Vessel No." name="voyageNo" defaultValue={this.props.import ? this.props.import.voyageNo : ''} />
        <Form.Input type="text" placeholder="Port of Loading" name="portOfLoading" defaultValue={this.props.import ? this.props.import.portOfLoading : ''} />
        <Form.Input type="text" placeholder="Port of Discharge" name="portOfDischarge" defaultValue={this.props.import ? this.props.import.portOfDischarge : ''} />
        <Form.Input type="text" placeholder="Place of Delivery" name="placeOfDelivery" defaultValue={this.props.import ? this.props.import.placeOfDelivery : ''} />
        <Form.Input type="text" placeholder="Final Destination" name="finalDestination" defaultValue={this.props.import ? this.props.import.finalDestination : ''} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input type="text" placeholder="Entry Date" name="entryDate" defaultValue={this.props.import ? this.props.import.entryDate : ''} />
        <Form.Input type="text" placeholder="IT No." name="itNo" defaultValue={this.props.import ? this.props.import.itNo : ''} />
        <Form.Input type="text" placeholder="IT Date" name="itDate" defaultValue={this.props.import ? this.props.import.itDate : ''} />
        <Form.Input type="text" placeholder="IT Place" name="itPlace" defaultValue={this.props.import ? this.props.import.itPlace : ''} />
        <Form.Input type="text" placeholder="ETD" name="etd" defaultValue={this.props.import ? this.props.import.etd : ''} />
        <Form.Input type="text" placeholder="ETA" name="eta" defaultValue={this.props.import ? this.props.import.eta : ''} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input type="text" placeholder="Freight Location" name="freightLocation" defaultValue={this.props.import ? this.props.import.freightLocation : ''} />
        <Form.Input type="text" placeholder="Firm Code" name="firmCode" defaultValue={this.props.import ? this.props.import.firmCode : ''} />
        <Form.Input type="text" placeholder="Last Free Date" name="lastFreeDate" defaultValue={this.props.import ? this.props.import.lastFreeDate : ''} />
        <Form.Input type="text" placeholder="Place of Delivery ETA" name="placeOfDeliveryEta" defaultValue={this.props.import ? this.props.import.placeOfDeliveryEta : ''} />
        <Form.Input type="text" placeholder="Final Destination ETA" name="finalDestinationEta" defaultValue={this.props.import ? this.props.import.finalDestinationEta : ''} />
      </Form.Group>
      <Divider />
      <Header as="h4">Cargo Information</Header>
      <Form.Group widths="4">
        <Form.Input type="text" placeholder="Master BL No." name="mblNo" defaultValue={this.props.import ? this.props.import.mblNo : ''} />
        <Form.Input type="text" placeholder="AMS House BL No." name="amshblNo" defaultValue={this.props.import ? this.props.import.amshblNo : ''} />
        <Form.Input type="text" placeholder="House BL No." name="hblNo" defaultValue={this.props.import ? this.props.import.hblNo : ''} />
      </Form.Group>
      <Form.TextArea type="text" label="Description of Goods" name="descriptionOfGoods" defaultValue={this.props.import ? this.props.import.descriptionOfGoods : ''} />
      {this.state.cargo.map((cargoItem, index) => <Form.Group>
        <Form.Input type="text" label="Container No." name={index + '_containerNo'} defaultValue={cargoItem.containerNo} onChange={this.onChangeCargoItem} width={3} />
        <Form.Input type="text" label="Seal No." name={index + '_sealNo'} defaultValue={cargoItem.sealNo} onChange={this.onChangeCargoItem} width={3} />
        <Form.Field label="Container Type" name={index + '_containerType'} defaultValue={cargoItem.containerType} control='select' onChange={this.onChangeCargoItem} width={3}>
          <option value="">Container Type</option>
          <option value="1x20'GP">1x20'GP</option>
          <option value="1x20'HC">1x20'HC</option>
          <option value="1x20'OT">1x20'OT</option>
          <option value="1x40'GP">1x40'GP</option>
          <option value="1x40'HC">1x40'HC</option>
          <option value="1x40'RH">1x40'RH</option>
          <option value="1x40'OT">1x40'OT</option>
          <option value="1x40'NOR">1x40'NOR</option>
          <option value="1x45'GP">1x45'GP</option>
          <option value="1x45'HC">1x45'HC</option>
          <option value="LCL">LCL</option>
        </Form.Field>
        <Form.Input type="number" step="any" min="0" label="Weight" name={index + '_weight'} defaultValue={cargoItem.weight} onChange={this.onChangeCargoItem} width={2} />
        <Form.Input type="number" step="any" min="0" label="Quantity" name={index + '_quantity'} defaultValue={cargoItem.quantity} onChange={this.onChangeCargoItem} width={2} />
        <Form.Input type="text" label="Units" name={index + '_units'} defaultValue={cargoItem.units} onChange={this.onChangeCargoItem} width={2} />
        <Form.Input type="number" step="any" min="0" label="Measurement" name={index + '_measurement'} defaultValue={cargoItem.measurement} onChange={this.onChangeCargoItem} width={2} />
        <Form.Button type="button" label=" " icon="x" onClick={() => this.removeCargoItem(index)} />
      </Form.Group>)}
      <Form.Button type="button" onClick={this.addCargoItem}>Add New Cargo Item</Form.Button>
      <Form.Group widths="3">
        <Form.Field label='Original BL Required' name="originalBLRequired" defaultValue={this.props.import ? this.props.import.originalBLRequired : ''} control='select'>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
          <option value=''>Unspecified</option>
        </Form.Field>
        <Form.Field label='Freight Charge' name="freightCharge" defaultValue={this.props.import ? this.props.import.freightCharge : ''} control='select'>
          <option value='prepaid'>Prepaid</option>
          <option value='collect'>Collect</option>
          <option value=''>Unspecified</option>
        </Form.Field>
        <Form.Field label='Shipper Owned Container' name="shipperOwnedContainer" defaultValue={this.props.import ? this.props.import.shipperOwnedContainer : ''} control='select'>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
          <option value=''>Unspecified</option>
        </Form.Field>
      </Form.Group>
      <Form.Group widths="2">
        <Form.Input type="text" placeholder="Container Return Location" name="containerReturnLocation" defaultValue={this.props.import ? this.props.import.containerReturnLocation : ''} />
      </Form.Group>
      <Divider />
      <Header as="h4">Entity Information</Header>
      <Form.Group widths="three">
        <Form.Input type="text" label="Shipper Name" name="shipperName" defaultValue={this.props.import ? this.props.import.shipperName : ''} />
      </Form.Group>
      <Form.TextArea type="text" label="Address" name="shipperAddress" defaultValue={this.props.import ? this.props.import.shipperAddress : ''} />
      <Form.Group widths="four">
        <Form.Input type="text" placeholder="Phone No." name="shipperPhoneNo" defaultValue={this.props.import ? this.props.import.shipperPhoneNo : ''} />
        <Form.Input type="text" placeholder="Fax No." name="shipperFaxNo" defaultValue={this.props.import ? this.props.import.shipperFaxNo : ''} />
        <Form.Input type="text" placeholder="Contact" name="shipperContact" defaultValue={this.props.import ? this.props.import.shipperContact : ''} />
      </Form.Group>
      <Divider />
      <Form.Group widths="three">
        <Form.Input type="text" label="Consignee Name" name="consigneeName" defaultValue={this.props.import ? this.props.import.consigneeName : ''} />
      </Form.Group>
      <Form.TextArea type="text" label="Address" name="consigneeAddress" defaultValue={this.props.import ? this.props.import.consigneeAddress : ''} />
      <Form.Group widths="four">
        <Form.Input type="text" placeholder="Phone No." name="consigneePhoneNo" defaultValue={this.props.import ? this.props.import.consigneePhoneNo : ''} />
        <Form.Input type="text" placeholder="Fax No." name="consigneeFaxNo" defaultValue={this.props.import ? this.props.import.consigneeFaxNo : ''} />
        <Form.Input type="text" placeholder="Contact" name="consigneeContact" defaultValue={this.props.import ? this.props.import.consigneeContact : ''} />
      </Form.Group>
      <Divider />
      <Form.Group widths="three">
        <Form.Input type="text" label="Notify Party" name="notifyParty" defaultValue={this.props.import ? this.props.import.notifyParty : ''} />
      </Form.Group>
      <Form.TextArea type="text" label="Address" name="notifyAddress" defaultValue={this.props.import ? this.props.import.notifyAddress : ''} />
      <Form.Group widths="four">
        <Form.Input type="text" placeholder="Phone No." name="notifyPhoneNo" defaultValue={this.props.import ? this.props.import.notifyPhoneNo : ''} />
        <Form.Input type="text" placeholder="Fax No." name="notifyFaxNo" defaultValue={this.props.import ? this.props.import.notifyFaxNo : ''} />
        <Form.Input type="text" placeholder="Contact" name="notifyContact" defaultValue={this.props.import ? this.props.import.notifyContact : ''} />
      </Form.Group>
      <Divider />
      <Header as="h4">Invoice Information</Header>
      <Form.Field label='Bill To' name="billTo" defaultValue={this.props.import ? this.props.import.billTo : ''} control='select' onChange={this.onChangeBillTo}>
        <option value='consignee'>Consignee</option>
        <option value='consignee_invoice'>Consignee (Add Invoice)</option>
        <option value='other'>Other</option>
      </Form.Field>
      {this.state.billToOther && <Form.TextArea type="text" label="Address" name="billToOther" defaultValue={this.props.import ? this.props.import.billToOther : ''} />}
      <Divider />
      {this.state.invoice.map((billingItem, index) => <Form.Group>
        <Form.Input type="text" label="Description" name={index + '_desc'} defaultValue={billingItem.desc} onChange={this.onChangeBillingItem} width={12} />
        <Form.Input type="number" label="Prepaid" step="any" name={index + '_prepaid'} defaultValue={billingItem.prepaid} onChange={this.onChangeBillingItem} width={2} />
        <Form.Input type="number" label="Collect" step="any" name={index + '_collect'} defaultValue={billingItem.collect} onChange={this.onChangeBillingItem} width={2} />
        <Form.Button type="button" label=" " icon="x" onClick={() => this.removeBillingItem(index)} />
      </Form.Group>)}
      <Form.Button type="button" onClick={this.addBillingItem}>Add New Billing Item</Form.Button>
      <Divider />
      <Checkbox toggle label={this.props.altSubmitText} name="submitButton" defaultChecked={this.props.altSubmitDefault} />
      <Divider hidden />
      <Form.Button primary type="submit">Save Import</Form.Button>
      <Divider hidden />
      <Divider hidden />
    </Form>
  }
}

export default ImportForm
