import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import Error from '../misc/Error'
import FirebaseAuth from '../misc/FirebaseAuth'
import deleteImport from '../../actions/deleteImport'
import deleteImportTemplate from '../../actions/deleteImportTemplate'
import generateArrivalNotice from '../../actions/generateArrivalNotice'
import generateImportInvoice from '../../actions/generateImportInvoice'
import generateImportDisclaimer from '../../actions/generateImportDisclaimer'
import generateTurnover from '../../actions/generateTurnover'
import { Link } from 'react-router-dom'
import { Header, Button, Card, Icon, Divider, Grid, Table } from 'semantic-ui-react'

const Import = ({ match, history, isTemplate }) => (
  <FirestoreCollection
    path={(isTemplate) ? 'importTemplates' : 'imports'}
    filter={['slug', '==', match.params.slug]}
  >
    {({ error, isLoading, data }) => {
      if (error) {
        return <Error error={error} />
      }

      if (isLoading) {
        return <p>Loading...</p>
      }

      if (data.length === 0) {
        return <Error />
      }

      const importFile = data[0]
      const invoice = JSON.parse(importFile.invoice)
      const cargo = (importFile.cargo) ? JSON.parse(importFile.cargo) : null

      //Legacy Indicator
      const legacy = (!importFile.cargo)

      return <React.Fragment>
        {isTemplate ? (
          <Header as="h1">
            <Header.Content>Template: {importFile.fileNo}</Header.Content>
            <Header.Subheader>
              Importer: {importFile.importer} <br />
            </Header.Subheader>
          </Header>
        ) : (
            <React.Fragment>
              <Header as="h1">
                <Header.Content>File: {importFile.fileNo}</Header.Content>
                <Header.Subheader>
                  Importer: {importFile.importer} <br />
                  Status: {(importFile.fileStatus === true) ? "Completed" : "Incomplete"}
                </Header.Subheader>
              </Header>

              {legacy ? (
                <Header as="h2" textAlign='center'>
                  <Header.Content><Icon name='warning' />Legacy File Format</Header.Content>
                  <Header.Subheader>
                    &nbsp;&nbsp;Please update file to current version.
                </Header.Subheader>
                </Header>
              ) : (
                  <Card.Group>
                    <Card>
                      <Card.Content>
                        <Card.Header>Arrival Notice</Card.Header>
                        <Card.Description>
                          Ready to generate.
              </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Button primary icon labelPosition='right' onClick={() => generateArrivalNotice(importFile)} >
                          Download
                < Icon name='download' />
                        </Button>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Turn Over</Card.Header>
                        <Card.Description>
                          Ready to generate.
              </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Button primary icon labelPosition='right' onClick={() => generateTurnover(importFile)} >
                          Download
                <Icon name='download' />
                        </Button>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Disclaimer Letter</Card.Header>
                        <Card.Description>
                          Ready to generate.
              </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Button primary icon labelPosition='right' onClick={() => generateImportDisclaimer(importFile)}>
                          Download
                <Icon name='download' />
                        </Button>
                      </Card.Content>
                    </Card>
                    {(importFile.billTo === "other" || importFile.billTo === "consignee_invoice") && <Card>
                      <Card.Content>
                        <Card.Header>Invoice</Card.Header>
                        <Card.Description>
                          Ready to generate.
              </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Button primary icon labelPosition='right' onClick={() => generateImportInvoice(importFile)}>
                          Download
                <Icon name='download' />
                        </Button>
                      </Card.Content>
                    </Card>}
                  </Card.Group>
                )}
            </React.Fragment>
          )}

        <Divider />
        <div >
          <Header as="h2">Summary</Header>
          <FirebaseAuth>
            {({ auth }) => (
              (auth && (auth.uid === importFile.createdBy || isTemplate)) ? <Button.Group>
                <Button as={Link} to={(isTemplate) ? `/imports/${importFile.slug}/template/edit` : `/imports/${importFile.slug}/edit`}
                  icon labelPosition='left'>{(legacy) ? "Update File" : "Edit File"}<Icon name='edit' /></Button>
                {(isTemplate) ? (
                  <Button negative onClick={() => deleteImportTemplate(importFile).then(() => history.push(`/imports/`))}
                    icon labelPosition='right'><Icon name='delete' />Delete File</Button>
                ) : (
                    <Button negative onClick={() => deleteImport(importFile).then(() => history.push(`/imports/`))}
                      icon labelPosition='right'><Icon name='delete' />Delete File</Button>
                  )}
              </Button.Group> : null
            )}
          </FirebaseAuth>
        </div>
        <Divider hidden />
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>Vessel and Destination Information</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Vessel Name</Table.Cell>
                    <Table.Cell>{importFile.vesselName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Voyage/Flight No.</Table.Cell>
                    <Table.Cell>{importFile.voyageNo}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Port of Loading</Table.Cell>
                    <Table.Cell>{importFile.portOfLoading}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Port of Discharge</Table.Cell>
                    <Table.Cell>{importFile.portOfDischarge}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Place of Delivery</Table.Cell>
                    <Table.Cell>{importFile.placeOfDelivery}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Final Destination</Table.Cell>
                    <Table.Cell>{importFile.finalDestination}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Entry Date</Table.Cell>
                    <Table.Cell>{importFile.entryDate}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>IT No.</Table.Cell>
                    <Table.Cell>{importFile.itNo}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>IT Date</Table.Cell>
                    <Table.Cell>{importFile.itDate}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>IT Place</Table.Cell>
                    <Table.Cell>{importFile.itPlace}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ETD</Table.Cell>
                    <Table.Cell>{importFile.etd}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ETA</Table.Cell>
                    <Table.Cell>{importFile.eta}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Place of Delivery ETA</Table.Cell>
                    <Table.Cell>{importFile.placeOfDeliveryEta}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Final Destination ETA</Table.Cell>
                    <Table.Cell>{importFile.finalDestinationEta}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Freight Location</Table.Cell>
                    <Table.Cell>{importFile.freightLocation}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Firm Code</Table.Cell>
                    <Table.Cell>{importFile.firmCode}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Last Free Date</Table.Cell>
                    <Table.Cell>{importFile.lastFreeDate}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>Cargo Information</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Master BL No.</Table.Cell>
                    <Table.Cell>{importFile.mblNo}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>AMS House BL No.</Table.Cell>
                    <Table.Cell>{importFile.amshblNo}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>House BL No.</Table.Cell>
                    <Table.Cell>{importFile.hblNo}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Quantity</Table.Cell>
                    <Table.Cell>{importFile.cargoQuantity}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Weight</Table.Cell>
                    <Table.Cell>{importFile.cargoGrossWeight}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Measurement</Table.Cell>
                    <Table.Cell>{importFile.cargoMeasurement}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Original BL Required</Table.Cell>
                    <Table.Cell>{importFile.originalBLRequired}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Freight Charge</Table.Cell>
                    <Table.Cell>{importFile.freightCharge}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Shipper Owned Container</Table.Cell>
                    <Table.Cell>{importFile.shipperOwnedContainer}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Container Return Location</Table.Cell>
                    <Table.Cell>{importFile.containerReturnLocation}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          {(!legacy) &&
            <Grid.Row style={{ padding: "1%" }}>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Container No.</Table.HeaderCell>
                    <Table.HeaderCell>Seal No.</Table.HeaderCell>
                    <Table.HeaderCell>Container Type</Table.HeaderCell>
                    <Table.HeaderCell>Weight</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Units</Table.HeaderCell>
                    <Table.HeaderCell>Measurement</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {cargo.map((cargoItem) => <Table.Row>
                    <Table.Cell>{cargoItem.containerNo}</Table.Cell>
                    <Table.Cell>{cargoItem.sealNo}</Table.Cell>
                    <Table.Cell>{cargoItem.containerType}</Table.Cell>
                    <Table.Cell>{cargoItem.weight}</Table.Cell>
                    <Table.Cell>{cargoItem.quantity}</Table.Cell>
                    <Table.Cell>{cargoItem.units}</Table.Cell>
                    <Table.Cell>{cargoItem.measurement}</Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </Grid.Row>}
          <Grid.Row style={{ padding: "1%" }}>
            <Header as="h3">
              <Header.Content>Billing Information</Header.Content>
              <Header.Subheader>
                {(importFile.billTo === "consignee") ? "Billed to Consignee" : "Billed To: " + importFile.billToOther}
              </Header.Subheader>
            </Header>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Prepaid</Table.HeaderCell>
                  <Table.HeaderCell>Collect</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {invoice.map((billItem) => <Table.Row>
                  <Table.Cell>{billItem.desc}</Table.Cell>
                  <Table.Cell>${Number(billItem.prepaid).toFixed(2)}</Table.Cell>
                  <Table.Cell>${Number(billItem.collect).toFixed(2)}</Table.Cell>
                </Table.Row>)}
              </Table.Body>
            </Table>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Header as="h3" >Shipper</Header>
              <Card>
                <Card.Content>
                  <Card.Header>{importFile.shipperName}</Card.Header>
                  <Card.Meta>{importFile.shipperContact}</Card.Meta>
                  <Card.Description>
                    {importFile.shipperAddress}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  Phone: {importFile.shipperPhoneNo} | Fax: {importFile.shipperFaxNo}
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3" >Consignee</Header>
              <Card>
                <Card.Content>
                  <Card.Header>{importFile.consigneeName}</Card.Header>
                  <Card.Meta>{importFile.consigneeContact}</Card.Meta>
                  <Card.Description>
                    {importFile.consigneeAddress}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  Phone: {importFile.consigneePhoneNo} | Fax: {importFile.consigneeFaxNo}
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3" >Notify Party</Header>
              <Card>
                <Card.Content>
                  <Card.Header>{importFile.notifyParty}</Card.Header>
                  <Card.Meta>{importFile.notifyContact}</Card.Meta>
                  <Card.Description>
                    {importFile.notifyAddress}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  Phone: {importFile.notifyPhoneNo} | Fax: {importFile.notifyFaxNo}
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

      </React.Fragment>
    }}
  </FirestoreCollection >
)

export default Import
