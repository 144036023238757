import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';

import templateXML from '../templates/templateArrivalNoticeCont'
import hostDocument from '../templates/templateArrivalNoticeCont.xlsx'

const generateArrivalNoticeCont = (fileNo, mbl, hbl, containers) => {
  fetch(templateXML)
    .then((r) => r.text())
    .then(xmlText => {
      xmlText = xmlText.replace("_mblNoReplace", mbl)
      xmlText = xmlText.replace("_hblNoReplace", hbl)
      xmlText = xmlText.replace("_containersReplace", containers)

      JSZipUtils.getBinaryContent(hostDocument, function (err, data) {
        if (err) {
          throw err
        }

        JSZip.loadAsync(data).then((loadedDocument) => {
          loadedDocument.file("xl/sharedStrings.xml", xmlText)
          loadedDocument.generateAsync({ type: "blob" })
            .then(function (blob) {
              saveAs(blob, fileNo + " - Arrival Notice Cont'd.xlsx")
            })
        })
      })
    })
}

export default generateArrivalNoticeCont
