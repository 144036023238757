import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateImportTemplate = (templateId, values) => {

  ReactGA.event({
    category: 'Import Template',
    action: 'Update template',
  })

  values.lastUpdatedTimestamp = (new Date()).getTime();
  values.ownedByName = Firebase.auth().currentUser.displayName

  return Firebase.firestore()
    .collection('importTemplates')
    .doc(templateId)
    .update(prepareDocForUpdate(values))
    .catch(error => {
      alert(`Error! Couldn't edit the template: ${error.message}`)
    })
}

export default updateImportTemplate
