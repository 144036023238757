// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import ImportList from './imports/ImportList'
import ImportNew from './imports/ImportNew'
import Search from './search/Search'
import ImportEdit from './imports/ImportEdit'
import Import from './imports/Import'
import Error from './misc/Error'

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/imports/" component={ImportList} />
    <Route path="/imports/new" component={ImportNew} />
    <Route path="/imports/search" component={Search} />
    <Route path="/imports/:slug/template/edit"
      render={(props) => <ImportEdit {...props} isTemplate={true} />} />
    <Route path="/imports/:slug/template"
      render={(props) => <Import {...props} isTemplate={true} />} />
    <Route path="/imports/:slug/edit" component={ImportEdit} />
    <Route path="/imports/:slug" component={Import} />
    <Route component={Error} />
  </Switch>
)

export default Routes
