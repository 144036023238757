// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React from 'react'
import { Route } from 'react-router-dom'

import logIn from '../../actions/logIn'
import logOut from '../../actions/logOut'
import FirebaseAuth from '../misc/FirebaseAuth'
import { Link } from 'react-router-dom'
import {
  Button,
  Container,
  Image,
  Menu,
  Dropdown,
  Card,
  Icon,
  Loader,
  Grid,
  Header,
  Message
} from 'semantic-ui-react'

import sunstar_img from '../../media/sunstar.png'

const AppLayout = ({ children }) => (
  <Route render={({ history }) => (
    <div>
      <Menu fixed='top'>
        <Container>
          <Link to="/">
            <Menu.Item header className='borderless'>
              <Image size='mini' src={sunstar_img} style={{ marginRight: '0.5em' }} />
              <h3 style={{ marginTop: '0.1em' }}>Sunstar International</h3>
            </Menu.Item>
          </Link>

          <Menu.Item as={Link} name='import' to='/imports/'>Import</Menu.Item>
          <Menu.Item as={Link} name='export' to='/exports/'>Export</Menu.Item>
          <Menu.Item as={Link} name='trucking' to='/trucking/' >Trucking</Menu.Item>

          <FirebaseAuth>
            {({ isLoading, error, auth }) => {
              if (isLoading) {
                return ''
              }
              if (error) {
                return ''
              }
              if (auth) {
                return <Menu.Menu position='right'>
                  <Image avatar src={auth.photoURL} style={{ marginTop: '1.2em', marginRight: '0em' }} />
                  <Dropdown text={auth.displayName} className='link item borderless'>
                    <Dropdown.Menu>
                      <Card>
                        <Card.Content>
                          <Image src={auth.photoURL} alt={auth.displayName} size="tiny" floated='right' />
                          <Card.Header>{auth.displayName}</Card.Header>
                          <Card.Meta>{auth.email}</Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                          <Button onClick={() => logOut().then(() => history.push(`/`))}>Log Out</Button>
                        </Card.Content>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              } else {
                return <Menu.Item position='right'>
                  <Button color='primary' onClick={logIn}><Icon name='google' />Sign in with Google</Button>
                </Menu.Item>
              }
            }}
          </FirebaseAuth>
        </Container>
      </Menu>


      <FirebaseAuth>
        {({ isLoading, error, auth }) => {
          if (isLoading) {
            return <Container style={{ marginTop: '7em' }}><Loader active inline='centered' /></Container>
          }
          if (error) {
            return ''
          }
          if (auth) {
            return <Container style={{ marginTop: '7em' }}>{children}</Container>
          } else {
            return <Container style={{ marginTop: '7em' }}>
              <Grid textAlign='center' style={{ height: '70vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                  <Header as='h2' color='teal' textAlign='center'>
                    <Icon name='user cancel' />You must log in to access this site
                  </Header>
                  <Button color='primary' onClick={logIn}><Icon name='google' />Sign in with Google</Button>
                  <Message>
                    Questions? Contact us at <a href="mailto:jenny@sunstarintl.com">jenny@sunstarintl.com</a>
                  </Message>
                </Grid.Column>
              </Grid>
            </Container>
          }
        }}
      </FirebaseAuth>


    </div>
  )} />
)

export default AppLayout
