import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createImportTemplate = values => {

  ReactGA.event({
    category: 'Import Template',
    action: 'Create Template',
  })

  values.ownedByName = Firebase.auth().currentUser.displayName
  values.lastUpdatedTimestamp = (new Date()).getTime();
  values.slug = slugify(values.fileNo, { lower: true })

  Firebase.firestore().collection('statistics').doc('importCollections').update({
    importTemplates: Firebase.firestore.FieldValue.increment(1)
  });

  return Firebase.firestore()
    .collection('importTemplates')
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch(error => {
      alert(`Error! Could not create a new template: ${error.message}`)
    })
}

export default createImportTemplate
