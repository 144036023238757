import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';

import escapeXMLChars from './helpers/escapeXMLChars'
import templateXML from '../templates/templateTurnover'
import hostDocument from '../templates/templateTurnover.xlsx'

const generateTurnover = importFile => {
  fetch(templateXML)
    .then((r) => r.text())
    .then(xmlText => {
      for (let key in importFile) {
        if (importFile.hasOwnProperty(key)) {
          if (key.indexOf("PhoneNo") !== -1 && importFile[key].length !== 0)
            importFile[key] = "TEL: " + importFile[key].replace("TEL: ", "")
          if (key.indexOf("FaxNo") !== -1 && importFile[key].length !== 0)
            importFile[key] = "FAX: " + importFile[key].replace("FAX: ", "")
          xmlText = xmlText.replace("_" + key + "Replace", escapeXMLChars(importFile[key]))
        }
      }
      xmlText = xmlText.replace("_currentDateReplace", new Date().toLocaleDateString())

      JSZipUtils.getBinaryContent(hostDocument, function (err, data) {
        if (err) {
          throw err
        }

        JSZip.loadAsync(data).then((loadedDocument) => {
          loadedDocument.file("xl/sharedStrings.xml", xmlText)
          loadedDocument.generateAsync({ type: "blob" })
            .then(function (blob) {
              saveAs(blob, importFile.fileNo + " - Arrival Notice.xlsx")
            })
        })
      })
    })
}

export default generateTurnover
