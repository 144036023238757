import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import Error from '../misc/Error'
import createImport from '../../actions/createImport'
import createImportTemplate from '../../actions/createImportTemplate'
import updateImport from '../../actions/updateImport'
import updateImportTemplate from '../../actions/updateImportTemplate'
import ImportForm from './ImportForm'
import { Divider } from 'semantic-ui-react'

const ImportEdit = ({ match, history, isTemplate }) => (
  <FirestoreCollection
    path={(isTemplate) ? 'importTemplates' : 'imports'}
    filter={['slug', '==', match.params.slug]}
  >
    {({ error, isLoading, data }) => {
      if (error) {
        return <Error error={error} />
      }

      if (isLoading) {
        return <p>loading...</p>
      }

      if (data.length === 0) {
        return <Error />
      }

      const importFile = data[0]

      return <React.Fragment>
        {isTemplate ? (
          <ImportForm
            import={importFile}
            onSubmit={values => updateImportTemplate(importFile.id, values).then(() => history.push(`/imports/${importFile.slug}/template`))}
            onAltSubmit={values => createImport(values).then(importFile => history.push(`/imports/${importFile.slug}`))}
            altSubmitText={"Save as Import"}
          />
        ) : (
            <ImportForm
              import={importFile}
              onSubmit={values => updateImport(importFile.id, values).then(() => history.push(`/imports/${importFile.slug}`))}
              onAltSubmit={values => createImportTemplate(values).then(importFile => history.push(`/imports/${importFile.slug}/template`))}
              altSubmitText={"Save as New Template"}
            />
          )}
        <Divider hidden />
        <Divider hidden />
      </React.Fragment>
    }}
  </FirestoreCollection >
)

export default ImportEdit
