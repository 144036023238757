import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import Error from './misc/Error'
import { Link } from 'react-router-dom'
import { format } from 'timeago.js';
import {
    Icon,
    Table,
    Button,
    Divider
} from 'semantic-ui-react'

const Home = () => (
    <React.Fragment>
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='10'>Imports on File</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>File No.</Table.HeaderCell>
                    <Table.HeaderCell>MBL No.</Table.HeaderCell>
                    <Table.HeaderCell>HBL No.</Table.HeaderCell>
                    <Table.HeaderCell>Importer</Table.HeaderCell>
                    <Table.HeaderCell>Notify Party</Table.HeaderCell>
                    <Table.HeaderCell>ETD</Table.HeaderCell>
                    <Table.HeaderCell>ETA</Table.HeaderCell>
                    <Table.HeaderCell>Last Updated</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <FirestoreCollection
                path={'imports'}
                sort="fileNo:desc"
                limit={5}
            >
                {({ error, isLoading, data }) => {

                    if (error) {
                        return <Error error={error} />
                    }

                    if (isLoading) {
                        return <Table.Body>
                            <Table.Row>
                                <Table.Cell>Loading, Please wait...</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    }

                    if (data.length === 0) {
                        return <Table.Body>
                            <Table.Row>
                                <Table.Cell>No imports found</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    }

                    return <Table.Body>
                        {data.map(importFile => (
                            <Table.Row key={importFile.id}>
                                <Table.Cell collapsing>
                                    {(importFile.fileStatus === true) ? <Icon name='folder' size="large" color="green" />
                                        : <Icon name='folder open' size="large" color="yellow" />}&nbsp;
                          <Link to={`/imports/${importFile.slug}`}>{importFile.fileNo}</Link>
                                </Table.Cell>
                                <Table.Cell>{importFile.mblNo}</Table.Cell>
                                <Table.Cell>{importFile.hblNo}</Table.Cell>
                                <Table.Cell>{importFile.importer}</Table.Cell>
                                <Table.Cell>{importFile.notifyParty}</Table.Cell>
                                <Table.Cell>{importFile.etd}</Table.Cell>
                                <Table.Cell>{importFile.eta}</Table.Cell>
                                <Table.Cell collapsing textAlign='right'>
                                    {format((importFile.updatedOn) ? importFile.updatedOn.toDate() : importFile.createdOn.toDate())}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                }}
            </FirestoreCollection>
        </Table>
        <Button primary icon labelPosition='right' as={Link} to="/imports/" floated='right'>
            Go to Imports
                <Icon name='right arrow' />
        </Button>
        <Divider hidden /><Divider hidden /><Divider hidden /><Divider hidden /><Divider hidden />
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='10'>Recent Exports</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>File No.</Table.HeaderCell>
                    <Table.HeaderCell>MBL No.</Table.HeaderCell>
                    <Table.HeaderCell>HBL No.</Table.HeaderCell>
                    <Table.HeaderCell>Exporter</Table.HeaderCell>
                    <Table.HeaderCell>Notify Party</Table.HeaderCell>
                    <Table.HeaderCell>ETD</Table.HeaderCell>
                    <Table.HeaderCell>ETA</Table.HeaderCell>
                    <Table.HeaderCell>Last Updated</Table.HeaderCell>
                    <Table.HeaderCell>Owner</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <FirestoreCollection
                path={'exports'}
                sort="lastUpdatedTimestamp:desc"
                limit={5}
            >
                {({ error, isLoading, data }) => {

                    if (error) {
                        return <Error error={error} />
                    }

                    if (isLoading) {
                        return <Table.Body>
                            <Table.Row>
                                <Table.Cell>Loading, Please wait...</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    }

                    if (data.length === 0) {
                        return <Table.Body>
                            <Table.Row>
                                <Table.Cell>No exports found</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    }

                    return <Table.Body>
                        {data.map(exportFile => (
                            <Table.Row key={exportFile.id}>
                                <Table.Cell collapsing>
                                    <Icon name='folder' /> <Link to={`/imports/${exportFile.slug}`}>{exportFile.fileNo}</Link>
                                </Table.Cell>
                                <Table.Cell>{exportFile.mblNo}</Table.Cell>
                                <Table.Cell>{exportFile.hblNo}</Table.Cell>
                                <Table.Cell>{exportFile.importer}</Table.Cell>
                                <Table.Cell>{exportFile.notifyParty}</Table.Cell>
                                <Table.Cell>{exportFile.etd}</Table.Cell>
                                <Table.Cell>{exportFile.eta}</Table.Cell>
                                <Table.Cell collapsing textAlign='right'>
                                    {format((exportFile.updatedOn) ? exportFile.updatedOn.toDate() : exportFile.createdOn.toDate())}
                                </Table.Cell>
                                <Table.Cell>{exportFile.ownedByName}</Table.Cell>
                                <Table.Cell>{(exportFile.fileStatus === true) ? <Icon name='check circle' size="big" color="green" />
                                    : <Icon name='minus circle' size="big" color="yellow" />}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                }}
            </FirestoreCollection>
        </Table>
        <Button primary icon labelPosition='right' as={Link} to="/exports/" floated='right'>
            Go to Exports
                <Icon name='right arrow' />
        </Button>
    </React.Fragment>
)

export default Home
