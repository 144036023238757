import React from 'react'

import FirebaseAuth from '../misc/FirebaseAuth'
import Error from '../misc/Error'
import logIn from '../../actions/logIn'
import createImport from '../../actions/createImport'
import createImportTemplate from '../../actions/createImportTemplate'
import ImportForm from './ImportForm'
import { Divider } from 'semantic-ui-react'

const ImportNew = ({ history }) => (
  <FirebaseAuth>
    {({ isLoading, error, auth }) => {

      if (error) {
        return <Error error={error} />
      }

      if (isLoading) {
        return <div>loading...</div>
      }

      if (!auth) {
        return <div>
          <p>You must be logged in to add imports</p>
          <button onClick={logIn}>log in</button>
        </div>
      }

      return <React.Fragment>
        <ImportForm
          onSubmit={values => createImport(values).then(importFile => history.push(`/imports/${importFile.slug}`))}
          onAltSubmit={values => createImportTemplate(values).then(importFile => history.push(`/imports/${importFile.slug}/template`))}
          altSubmitText={"Save as New Template"}
        />
        <Divider hidden />
        <Divider hidden />
      </React.Fragment>

    }}
  </FirebaseAuth>
)

export default ImportNew
