import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';

import escapeXMLChars from './helpers/escapeXMLChars'
import templateXML from '../templates/templateArrivalNotice'
import hostDocument from '../templates/templateArrivalNotice.xlsx'
import generateArrivalNoticeCont from './generateArrivalNoticeCont'
import { checkedBox, uncheckedBox } from './helpers/checkboxXMLTemplates';


const generateArrivalNotice = importFile => {
  fetch(templateXML)
    .then((r) => r.text())
    .then(xmlText => {
      for (let key in importFile) {
        if (importFile.hasOwnProperty(key)) {
          if (key.indexOf("PhoneNo") !== -1 && importFile[key].length !== 0)
            importFile[key] = "TEL: " + importFile[key].replace("TEL: ", "")
          if (key.indexOf("FaxNo") !== -1 && importFile[key].length !== 0)
            importFile[key] = "FAX: " + importFile[key].replace("FAX: ", "")
          xmlText = xmlText.replace("_" + key + "Replace", escapeXMLChars(importFile[key]))
        }
      }
      xmlText = xmlText.replace("_currentDateReplace", new Date().toLocaleDateString())

      if (importFile.cargo) {
        let containers = ""
        let containerTypes = ""
        let containerTypeArray = ["1x20'GP", "1x20'HC", "1x20'OT", "1x40'GP", "1x40'HC", "1x40'RH", "1x40'OT", "1x40'NOR", "1x45'GP", "1x45'HC", "LCL"];
        let containerTypeMap = new Map();
        containerTypeArray.forEach(e => containerTypeMap.set(e, { val: 0 }));
        let containerPackages = 0
        let cargoGrossWeight = 0
        let cargoGrossMeasurement = 0
        let cargo = JSON.parse(importFile.cargo)
        for (let cargoItem in cargo) {
          containers = containers + `${cargo[cargoItem].containerNo} / ${cargo[cargoItem].sealNo} / ${cargo[cargoItem].quantity} ${cargo[cargoItem].units} / ${cargo[cargoItem].weight} KGS / ${cargo[cargoItem].measurement} CBM\n`
          containerTypeMap.get(cargo[cargoItem].containerType).val++;
          containerPackages += parseFloat(cargo[cargoItem].quantity)
          cargoGrossWeight += parseFloat(cargo[cargoItem].weight)
          cargoGrossMeasurement += parseFloat(cargo[cargoItem].measurement)
        }

        containerTypeArray.forEach(e => {
          if (containerTypeMap.get(e).val > 0) {
            containerTypes = containerTypes + containerTypeMap.get(e).val + e.substring(1) + "\n";
          }
        });

        containers = containers.trim()
        if (containers.split(/\r\n|\r|\n/).length < 4) {
          xmlText = xmlText.replace("_containersReplace", containers)
        } else {
          xmlText = xmlText.replace("_containersReplace", "See Attached Document")
          generateArrivalNoticeCont(importFile.fileNo, importFile.mblNo, importFile.hblNo, containers)
        }
        xmlText = xmlText.replace("_containerTypesReplace", containerTypes)
        xmlText = xmlText.replace("_containerPackagesTotalReplace", containerPackages.toFixed(0) + " " + cargo[0].units)
        xmlText = xmlText.replace("_cargoWeightsMeasurementsReplace", `${cargoGrossWeight.toFixed(0)} KGS\n${cargoGrossMeasurement.toFixed(0)} CBM\n`)
      }

      let invoiceDesc = ""
      let invoicePrepaid = ""
      let invoiceCollect = ""
      let invoicePrepaidTotal = 0
      let invoiceCollectTotal = 0
      if (importFile.billTo === "consignee") {
        let invoice = JSON.parse(importFile.invoice)
        for (let billingItem in invoice) {
          invoiceDesc = invoiceDesc + invoice[billingItem].desc + "\n"
          invoicePrepaid = invoicePrepaid + ((invoice[billingItem].prepaid != 0) ? "$ " + parseFloat(invoice[billingItem].prepaid).toFixed(2) : "") + "\n"
          invoiceCollect = invoiceCollect + ((invoice[billingItem].collect != 0) ? "$ " + parseFloat(invoice[billingItem].collect).toFixed(2) : "") + "\n"
          invoicePrepaidTotal += parseFloat(invoice[billingItem].prepaid)
          invoiceCollectTotal += parseFloat(invoice[billingItem].collect)
        }
      }

      xmlText = xmlText.replace("_invoiceDescReplace", invoiceDesc)
      xmlText = xmlText.replace("_invoicePrepaidReplace", invoicePrepaid)
      xmlText = xmlText.replace("_invoiceCollectReplace", invoiceCollect)
      xmlText = xmlText.replace("_invoicePrepaidTotalReplace", (invoicePrepaidTotal > 0) ? "$ " + invoicePrepaidTotal.toFixed(2) : "-")
      xmlText = xmlText.replace("_invoiceCollectTotalReplace", (invoiceCollectTotal > 0) ? "$ " + invoiceCollectTotal.toFixed(2) : "-")

      JSZipUtils.getBinaryContent(hostDocument, function (err, data) {
        if (err) {
          throw err
        }

        JSZip.loadAsync(data).then((loadedDocument) => {
          loadedDocument.file("xl/sharedStrings.xml", xmlText)
          // Shipper Owned Container
          if (importFile.shipperOwnedContainer === "yes"){
            loadedDocument.file("xl/ctrlProps/ctrlProp1.xml", checkedBox)
            loadedDocument.file("xl/ctrlProps/ctrlProp2.xml", uncheckedBox)
          }
          if (importFile.shipperOwnedContainer === "no"){
            loadedDocument.file("xl/ctrlProps/ctrlProp1.xml", uncheckedBox)
            loadedDocument.file("xl/ctrlProps/ctrlProp2.xml", checkedBox)
          }
          // Orignal BL Checkboxes
          if (importFile.originalBLRequired === "yes"){
            loadedDocument.file("xl/ctrlProps/ctrlProp3.xml", checkedBox)
            loadedDocument.file("xl/ctrlProps/ctrlProp4.xml", uncheckedBox)
          }
          if (importFile.originalBLRequired === "no"){
            loadedDocument.file("xl/ctrlProps/ctrlProp3.xml", uncheckedBox)
            loadedDocument.file("xl/ctrlProps/ctrlProp4.xml", checkedBox)
          }
          loadedDocument.generateAsync({ type: "blob" })
            .then(function (blob) {
              saveAs(blob, importFile.fileNo + " - Arrival Notice.xlsx")
            })
        })
      })
    })
}

export default generateArrivalNotice
